.front {

  // News
  .panel-appel-home {
    .top-section {
      background: url(../images/homepage_hero.jpg) no-repeat top center;
      background-size: cover;
      position: relative;

      color: $wcm-white;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 80px;
      font-size: 18px;
      a {
        color: $wcm-white;
      }
      &:before {
        background: $wcm-dark-orange;
        position: absolute;
        width: 100%;
        @include breakpoint($sm) {
          width: 50%;
        }
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        opacity: 0.8;

        /*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          opacity: 0.8;
        }*/
      }

      @supports (mix-blend-mode: multiply) {
        &:before {
          mix-blend-mode: multiply;
          opacity: 1;
        }
      }

      .contact-section-top {
        margin-top: 30px;
        .col-sm-6:first-child {
          border-top: 1px solid $wcm-white;
        }
      }

      .contact-section {
        padding: 20px 0px;
        a {
          margin: 20px 0;
          border-bottom: 1px solid transparent;
          display: inline-block;
          &:after{
            content: '\e80d';
            color: $wcm-white;
            padding-left: 5px;
            font-size: 60%;
            @include fontello();
          }
          &:hover {
              border-bottom: 1px solid;
          }
          /*&:first-child {
            border-right: 1px solid $wcm-white;
            margin-right: 30px;
            padding-right: 30px;
          }*/
        }

        .separator {
          border-right: 1px solid $wcm-white;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 40px;
          margin-right: 40px;
        }
      }
    }
    .news-section {
      background: $wcm-bg-gray;
      margin-bottom: 10px;
      padding-top: 10px;
      .pane-news-panel-pane-3 {
        .pane-title {
          color: $wcm-med-gray;
          text-align: center;
          font-family: $wcm-regular;
          font-size: 40px;
        }
        .view-news {
          padding-bottom: 40px;
          .teaser-title {
            margin-top: 30px;
            padding-bottom: 10px;
            a:hover {
              // border-bottom: 1px solid !important;
            }
          }
          .view-footer {
            clear: both;
            padding-top: 20px;
            text-align: center;
          }
        }
      }
    }

    .bottom-section {
      margin-bottom: 20px;
      .bottom-left {
        @include breakpoint($sm) {
          padding-right: 0;
        }
        img {
          width: 100%;
        }

        .container-fluid {
          .col-sm-6 {
            padding-left: 0;
            padding-right: 0;

            &:first-child {
              @include breakpoint($sm) {
                padding-right: 5px;
              }
            }
            &:last-child {
              @include breakpoint($sm) {
                padding-left: 12px;
              }
            }
          }
        }
      }
      .bottom-right {
        @include breakpoint($xs) {
          width: 100%;
        }
      }
    }

    .footer-section {
      .pane-labs-panel-pane-2 {
        position: relative;
        border-top: 5px solid $wcm-bright-orange;
        padding-top: 10px;
        height: 100%;
        &:hover {
          @include breakpoint($sm) {
            background: $wcm-bg-gray;
          }
        }

        .view-labs {
          @include clearfix();
          @include breakpoint($md) {
            width: 90%;
            margin: 0 auto;
          }
          padding-bottom: 15px;
          .views-row {
            h3 {
              border-bottom: 1px solid $wcm-border-gray;
              padding-bottom: 15px;
              a {
                display: block;

                &:after {
                  float: right;
                }
              }
            }
            // &:last-child {
            //   h3 {
            //     border-bottom: none;
            //   }
            // }
          }
        }

        .pane-title {
          color: $wcm-med-gray;
          text-align: center;
          font-family: $wcm-regular;
          font-size: 40px;
          margin-bottom: 10px;
        }
      }

      .pane-bundle-text {
        height: 100%;
        &:hover {
          @include breakpoint($sm) {
            background: $wcm-bg-gray;
          }
        }
        .pane-title {
          color: $wcm-med-gray;
          text-align: center;
          font-family: $wcm-regular;
          font-size: 40px;
          margin-bottom: 20px;
        }
        text-align: center;
        padding-top: 10px;
        border-top: 5px solid $wcm-bright-orange;

        .fieldable-panels-pane {
          @include breakpoint($md) {
            width: 80%;
            margin: 0 auto;
          }
        }
      }

      .footer-left {
        @include breakpoint($xs) {
          width: 100%;
        }
      }
      .footer-right {
        @include breakpoint($xs) {
          width: 100%;
        }
      }
    }
  }
}

.pane-news-panel-pane-5 {
  .pane-title {
    color: $wcm-med-gray;
    text-align: center;
    font-family: $wcm-regular;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .views-field-field-event-date {
    margin: 5px 0;
    font-weight: 700;
  }
  .view-footer {
    clear: both;
    padding-top: 20px;
    text-align: center;
  }
}
