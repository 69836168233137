/* =============================================================================
   ~Homepage
   ========================================================================== */

/**
 * Home Grid: Layout
 *
 */

 .title-bar {
  font-family: $wcm-regular;
  padding-top: 5px;
  @include breakpoint($xs) {
      font-size: 26px;
  }
}

.front #page,
.front .body-wrap {
	//background: $quadrary-color;
}

.front #main {
	max-width: 100%;
	margin: 0;
	padding: 0;
}

#region1 {
	width: 100%;
	//background: $quadrary-color;
	@include breakpoint($sm) {
	  width: 68%;
	  float: left;
	}
	@include breakpoint($md) {
	  width: 68%;
	  float: left;
	}
}

#region2 {
  //background: $quadrary-color;
  width: 100%;
  //background: $quadrary-color;
  @include breakpoint($sm) {
    width: 32%;
    float: left;
  }
  @include breakpoint($md) {
    width: 32%;
    float: left;
  }
}

#region3 {
  background: #e5e4e3;
  width: 100%;
  clear: both;
  @include breakpoint($sm) {
    float: left;
  }
  @include breakpoint($md) {
    float: left;
  }
}

// Create a mixin for tile centering

@mixin center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);  /* Opera */
  -moz-transform: translate(-50%, -50%);  /* Firefox */
}

/**
 * Home Grid: Gallery
 *
 */

.photo-gallery-feature {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 200px;
  overflow: hidden;

  @include breakpoint($sm) {
    height: 615px;
  }
  @include breakpoint($md) {
    height: 615px;
  }
}

.photo-gallery-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include breakpoint($sm) {
    height: 615px;
  }
  @include breakpoint($md) {
    height: 615px;
  }
}

.photo-gallery-image.one {
  background-image: url(../images/slide-1.jpg);
}

.photo-gallery-image.two {
  background-image: url(../images/slide-2.jpg);
}
.photo-gallery-image.three {
  background-image: url(../images/slide-3.jpg);
}

.photo-gallery-image.four {
  background-image: url(../images/slide-4.jpg);
}

.photo-gallery-image.five {
  background-image: url(../images/slide-5.jpg);
}

.photo-gallery-image.six {
  background-image: url(../images/slide-6.jpg);
}

.photo-gallery-image.seven {
  background-image: url(../images/slide-7.jpg);
}

.gallery-container {
  position: absolute;
  top: 33%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 101;
  @include breakpoint($sm) {
    top: 42%;
  }
  @include breakpoint($md) {
    margin: 0 auto;
    width: 80%;
    top: 42%;
  }
}

.gallery-container h1 {
  margin: 0;
  text-transform: uppercase;
  //font-family: $body-text;
  color: rgb(255,255,255);
  padding: 0;
  text-shadow: 1px 1px 2px rgba(51,56,60,.9);
  line-height: 1.0;
  border-bottom: 0 !important;
  font-size: 40px;
  @include breakpoint($sm){
    font-size: 54px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md){
    font-size: 64px;
  }
}

.gallery-container p {
  color: rgb(255,255,255);
  text-shadow: 1px 1px 1px rgba(96,100,106,.9);
  margin: 0 0 20px;
  padding-bottom: .25em;
  font-size: 18px;
  @include breakpoint($sm){
    font-size: 20px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md){
    font-size: 24px;
  }
}

.gallery-container a {
  text-decoration: none;
  //color: $alternate-gray;
}

.gallery-container a:hover {
  text-decoration: none;
}

.round-buttons {
  &:hover{
    color: $wcm-yellow;
    background-color: $wcm-bright-orange;
    border :1px solid #fff;
  }

}


/**
 * Home Grid: Tile Styles
 *
 */

.home-callout {
  @include box-sizing(border-box);
  position: relative;
  display: block;
  text-decoration: none;
}

.home-callout > p {
  margin: 0 0 15px;
  @include breakpoint($sm) {
    @include center();
    margin: 0;
  }
  @include breakpoint($md) {
    @include center();
    z-index: 90;
    margin: 0;
  }
}

.ie8 .home-callout > p {
  position: static;
}

.grid-overlay {
  @include center();
  display: none;
  z-index: 100;
  text-align: center;
  width: 100%;
  height: 100%;
  background: rgb(216,210,201); /* The Fallback */
  background: rgba(216,210,201,.9);
  h2 {
    font-size: 66px;
    @include center();
    margin: 0;
    text-transform: uppercase;
    color: $wcm-bright-orange;
  }
}

.hover .grid-overlay {
  @include breakpoint($md) {
    display: block;
  }
}

.home-callout .grid-overlay {
  background: rgb(229,228,227); /* The Fallback */
  background: rgba(229,228,227,.9);
}

.ie8 .hover .grid-overlay {
  display: none;
}

/**
 * Home Grid: News Tile
 *
 */

.news-container {
  padding: 5% 5% 2% 5%;
  @include breakpoint($sm) {
    height: 32.5%;
  }
  @include breakpoint($md) {
    height: 32.5%;
  }
}

.news-container p {
  //color: $alternate-gray;
  font-weight: 400;
  line-height: 1.3em;
  width: 92%;
  @include breakpoint($sm) {
    font-size: 18px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md) {
    font-size: 20px;
  }
}

.news-photo-block {
  background: url(../images/photo1.jpg) no-repeat;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  @include breakpoint($sm) {
    height: 35%;
  }
  @include breakpoint($md) {
    height: 35%;
  }
}

/**
 * Home Grid: About Tile
 *
 */

.about-alzheimer {
  background: #fff;
	clear: both;
	width: 100%;
	position: relative;
  padding: 5% 5% 2% 5%;
	@include breakpoint($sm){
		float: left;
		width: 50%;
    height: 340px;
	}
	@include breakpoint($md) {
		float: left;
		width: 50%;
    height: 340px;
	}
}

.about-container p {
  width: 92%;
  //color: $alternate-gray;
  font-weight: 400;
  line-height: 1.3em;
  @include breakpoint($sm) {
    font-size: 15px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md) {
    font-size: 20px;
  }
}

/**
 * Home Grid: Video Modal Tile
 *
 */

.video-section {
	background: url(../images/drpaul.jpg) no-repeat;
	height: 200px;
	width: 100%;
	background-size: cover;
  background-position: center;
  @include breakpoint($sm) {
		width: 50%;
		height: 340px;
		float: left;
	}
	@include breakpoint($md) {
		width: 50%;
		height: 340px;
		float: left;
	}
}

/**
 * Home Grid: Image Modal Tile (in place of Video Modal Tile)
 * Change file to set image
 *
 */

.homepage-hero_link{
    height: 200px;
    width: 100%;
  
    @include breakpoint($sm) {
      width: 50%;
      height: 340px;
      float: left;
    }
    @include breakpoint($md) {
      width: 50%;
      height: 340px;
      float: left;
    }
    overflow: hidden;
}

.homepage-hero_image {
  background: url(/sites/default/files/news_images/li_gan_final.jpg) no-repeat;
  background-size: cover;
  background-position: 0% 50%;
  overflow: hidden;
  width: 100%;

  &:hover{
    transform: scale(1.1);
  }
}



/**
 * Home Grid: Project Tile
 *
 */

.projects-container {
  padding: 5% 5% 2% 5%;
	@include breakpoint($sm) {
		height: 32.5%;
	}
	@include breakpoint($md) {
    height: 32.5%;
	}
}

.projects-container p {
	width: 92%;
  //color: $alternate-gray;
  font-weight: 400;
  line-height: 1.3;
  @include breakpoint($sm){
    font-size: 18px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md){
    font-size: 20px;
  }
}

/**
 * Home Grid: Donation Tile
 *
 */

.donation-photo {
	background: #e5e4e3 url(../images/photo3.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 150px;
	height: inherit;
	clear: both;
	@include breakpoint($sm) {
		height: 232px;
		width: 50%;
		float: left;
	}
	@include breakpoint($md) {
		height: 232px;
		width: 50%;
		float: left;
	}
}

.donation-container {
	width: 100%;
	clear: none;
	background: #e5e4e3;
	position: relative;
  padding: 5% 5% 2% 5%;
	@include breakpoint($sm) {
		float: left;
		width: 50%;
    height: 232px;
	}
	@include breakpoint($md) {
		float: left;
		width: 50%;
    height: 232px;
	}
}

.donation-container p {
  width: 92%;
  //color: $alternate-gray;
  font-weight: 400;
  line-height: 1.3em;
  @include breakpoint($sm) {
    font-size: 18px;
    transition: all .4s ease 0s;
  }
  @include breakpoint($md) {
    font-size: 20px;
  }
}

footer{
  float: left;
  width: 100%;
  padding-top: 0px;
}

a.fancybox-video{
  &:after{
    display: none!important;
  }
  border: 0;
}


//Footer style

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

//required style for icons from old base theme
.video .playhead {
    margin-top: 0px;
}

.video:hover .playhead, .video:focus .playhead {
    background-color: rgba(255,0,0,0.75);
}

/*.wcm-cta__tile{
  width: 100%;
  border-right: 0px;
  border: 1px solid #ccc!important;
}*/

.pane-profiles-panel-pane-2, .pane-profiles-panel-pane-9 {
  .view-content>h3{
    // font-size: 30px;
    margin-top: 20px;
    float: left;
  }
  .profile-teaser{
    width: 100%;
    float: left;
    margin: 10px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $wcm-border-gray;
  }
  h4.display-name{
      margin-top: 10px;
      a{
        color: $wcm-dark-gray;
      }
    }
  .profile-list-image{
    // float: left;
    margin-right: 20px;
    img{
      margin-bottom:0;
    }
  }
}
h4 a{
  color: $wcm-dark-gray;
}

.pane-profiles-panel-pane-9 {
  .profile-list-image {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .profile-details {
    @include breakpoint($xs) {
      padding-left: 0;
    }
  }
}

.pane-node-field-profile-image{
  float: left;
  margin-right: 20px;
}

.brand__lockup img{
  max-height: 84px;
}


.pane-node-field-professional-title{
  font-weight: bold;
}

.pane-custom.pane-1{
  .field-label{
    font-weight: bold;
    float: left;
    padding-right: 5px;
  }
}


#biblio-header {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

.biblio-category-section{
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

nav.pagination-wrapper{
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

.views-label-field-principal-investigator{
  float: left;
  font-weight: bold;
  padding-right: 5px;
}

.pane-news-panel-pane-1{
  .view-teaser{
    margin: 20px 0;
  }
  h3,h2{
    margin-top: 0;
  }
  .teaser-image img{
    margin-right: 20px;
  }
}

footer{
  margin-top: 40px;
}

#region2, #region1, #region3{
  background-color: $wcm-dark-orange;
  a{
    color: #fff;
    p{
      line-height: 1.6em;
    }
    .grid-overlay{
      h2{
        text-transform: capitalize;
      }
    }
  }
}

.home-callout{
  background-color: $wcm-dark-orange;
}

.pane-node-title, .pane-bundle-text{
  h1{
    margin-bottom: 20px;
  }
}


/*News node page style*/
.node-type-news-post{
  .post-date{
    text-transform: uppercase;
    color: #777;
    font-family: "1898Sans-Bold",sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .hero-image {
    text-align: center;
    img {
      width: auto;
    }
  }
}

/* =Sharing Icons
----------------------------------------------------------*/
/*
.social-share {
  margin: 15px 0;
  padding: 0;
}

.social-share li {
  display: inline-block;
  height: 30px;
  padding: 0 5px;
  @include text-hide();
}

.social-share a {
  color: $wcm-bright-orange;
  &:before {
    font-size: 22px;
    @include fontello();
    @include v-center;
  }
  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.social-share__facebook a {
  &:before {
    content:'\e804';
  }
}

.social-share__twitter a {
  &:before {
    content:'\e807';
  }
}

.social-share__linkedin a {
  &:before {
    content:'\e806';
  }
}*/

/**
 * Navigation Styling
 */
#primary-nav {
  .level-1 {
    width: 16.667%;
    padding: 8px 2px;
  }
}
.pane-projects-panel-pane-7 {
  .view-projects {
    border-top: 1px solid $wcm-border-gray;

    .views-field-field-project-investigator {
      .views-label {
        font-weight: bold;
      }
      .field-content {
        display: inline-block; 
      }
    }
  }
}
.pane-profiles-panel-pane-8 {
  .view-profiles {
    @include clearfix();
    border-top: 1px solid $wcm-border-gray;
    padding-top: 20px;
    margin-bottom: 20px;
    .view-header {
      text-transform: uppercase;
      color: $wcm-med-gray;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    .view-content {
      li {
        margin: 0;
        list-style: inside;
      }
    }
  }
}

.pane-node-field-lab-slideshow-images {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid $wcm-border-gray;
  a {
    border: none;
    width: 100%;

    img {
      width: 100%;
    }
  }

}


.view-featured-jobs {
  .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $wcm-border-gray;

  } 
}

.pane-labs-panel-pane-1 .view-labs {
  @include clearfix();

  .views-row {
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    &:nth-child(odd) {
      clear: left;
      @include breakpoint($sm) {
        padding-right: 5px;
      }
    }
    &:nth-child(even) {
      @include breakpoint($sm) {
        padding-left: 5px;
      }
    }
  }
  .views-field-title {
    // padding: 15px;
    position: relative;
    z-index: 1;
    h3 {
      margin: 0;
      a {
        color: $wcm-white;
        display: block;
        padding: 15px;
        position: absolute;
        text-align: center;
        width: 100%;
        background: $wcm-dark-orange;
        opacity: 0.8;
        mix-blend-mode: multiply;
        // background-blend-mode: multiply;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          // background: $wcm-dark-orange;
          // mix-blend-mode: multiply;
          // z-index: -1;
          // background-blend-mode: multiply;
        }
        &:after {
          color: $wcm-white;
        }
      }
    }
  }

  .views-field-field-lab-thumbnail-image {
    img {
      width: 100%;
    }
  }

  .views-field-nothing {
    position: relative;
    overflow: hidden;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    a span {
      color: $wcm-white;
      display: block;
      padding: 15px;
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $wcm-red;
        opacity: 0.8;
        z-index: 1;
        @supports (mix-blend-mode: multiply) {
          mix-blend-mode: multiply;
          opacity: 1;
        }
      }

      h3 {
        position: relative;
        color: $wcm-white;
        margin-top: 0;
        z-index: 2;
        &:after{
          content: '\e80d';
          color: $wcm-white;
          padding-left: 5px;
          font-size: 60%;
          @include fontello();
        }
      }
    }
    img {
      // position: relative;
      width: 100%;
      // mix-blend-mode: normal;
      @include transition(all 0.25s);
      // z-index: -1;
    }
  }
}

.border-callout {
  padding: 20px 0;
  margin: 20px 0;
  border: 2px solid #ddd;
  border-width: 2px 0;
}

.event-teaser {
  margin: 0 0 30px;
  .teaser-title {
    margin-top: 0;
  }
  .views-field-field-event-date {
    margin: 5px 0;
    font-weight: 700;
  }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}