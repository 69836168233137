.node-type-laboratory{
    .cycle-slideshow{
        border: none;
        
        .cycle-pause{
            position: absolute;
            display: block;
            top: 15px;
            left: 30px;
            width: 40px;
            height: 40px;
            line-height: 40px;
        }

        .cycle-pause, .cycle-prev, .cycle-next{
            font-size: 18px;
            font-family: 'Glyphicons Halflings';
            color: $wcm-white;
            background-color: $wcm-dark-orange;
            opacity: 0.7;
            z-index: 101;
            text-align: center;
            cursor: pointer;
      
            &:hover{
              opacity: .9;
            }
        }
      
          .cycle-pause:before{
            content: "\e073";
        }
      
          .cycle-pause.paused:before{
            content: "\e072";
        }
      
        .cycle-prev, .cycle-next {
            display: none;
            position: absolute;
            top: 38%;
            transform: translateY(-50%);
            width: 30px;
            height: 50px;
            line-height: 50px;
            opacity: 0.7;
            
            @include breakpoint($sm) {
              display: block;
            }
      
            @include breakpoint($md) {
              top: 50%;
              transform: translateY(-50%);
            }
      
        }

        .cycle-prev {
            left: 0;
      
            &:before{
              content: "\e079";
            }
        }
      
          .cycle-next {
            right: 0;
      
            &:before{
              content: "\e080";
            }
        }
    }
}