/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.front .panel-appel-home .top-section {
  background: black;
  background-size: cover;
  position: relative;
  color: #fff;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  font-size: 18px; }
  .front .panel-appel-home .top-section a {
    color: #fff; }
  .front .panel-appel-home .top-section:before {
    background: #cf4520;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    opacity: 0.8;
    /*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          opacity: 0.8;
        }*/ }
    @media screen and (min-width: 768px) {
      .front .panel-appel-home .top-section:before {
        width: 50%; } }
  @supports (mix-blend-mode: multiply) {
    .front .panel-appel-home .top-section:before {
      mix-blend-mode: multiply;
      opacity: 1; } }
  .front .panel-appel-home .top-section .contact-section-top {
    margin-top: 30px; }
    .front .panel-appel-home .top-section .contact-section-top .col-sm-6:first-child {
      border-top: 1px solid #fff; }
  .front .panel-appel-home .top-section .contact-section {
    padding: 20px 0px; }
    .front .panel-appel-home .top-section .contact-section a {
      margin: 20px 0;
      border-bottom: 1px solid transparent;
      display: inline-block;
      /*&:first-child {
            border-right: 1px solid $wcm-white;
            margin-right: 30px;
            padding-right: 30px;
          }*/ }
      .front .panel-appel-home .top-section .contact-section a:after {
        content: '\e80d';
        color: #fff;
        padding-left: 5px;
        font-size: 60%;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
      .front .panel-appel-home .top-section .contact-section a:hover {
        border-bottom: 1px solid; }
    .front .panel-appel-home .top-section .contact-section .separator {
      border-right: 1px solid #fff;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 40px;
      margin-right: 40px; }

.front .panel-appel-home .news-section {
  background: #f7f7f7;
  margin-bottom: 10px;
  padding-top: 10px; }
  .front .panel-appel-home .news-section .pane-news-panel-pane-3 .pane-title {
    color: #666666;
    text-align: center;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 40px; }
  .front .panel-appel-home .news-section .pane-news-panel-pane-3 .view-news {
    padding-bottom: 40px; }
    .front .panel-appel-home .news-section .pane-news-panel-pane-3 .view-news .teaser-title {
      margin-top: 30px;
      padding-bottom: 10px; }
    .front .panel-appel-home .news-section .pane-news-panel-pane-3 .view-news .view-footer {
      clear: both;
      padding-top: 20px;
      text-align: center; }

.front .panel-appel-home .bottom-section {
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .front .panel-appel-home .bottom-section .bottom-left {
      padding-right: 0; } }
  .front .panel-appel-home .bottom-section .bottom-left img {
    width: 100%; }
  .front .panel-appel-home .bottom-section .bottom-left .container-fluid .col-sm-6 {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 768px) {
      .front .panel-appel-home .bottom-section .bottom-left .container-fluid .col-sm-6:first-child {
        padding-right: 5px; } }
    @media screen and (min-width: 768px) {
      .front .panel-appel-home .bottom-section .bottom-left .container-fluid .col-sm-6:last-child {
        padding-left: 12px; } }
  @media screen and (max-width: 767px) {
    .front .panel-appel-home .bottom-section .bottom-right {
      width: 100%; } }

.front .panel-appel-home .footer-section .pane-labs-panel-pane-2 {
  position: relative;
  border-top: 5px solid #e7751d;
  padding-top: 10px;
  height: 100%; }
  @media screen and (min-width: 768px) {
    .front .panel-appel-home .footer-section .pane-labs-panel-pane-2:hover {
      background: #f7f7f7; } }
  .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs {
    padding-bottom: 15px; }
    .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs:before, .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs:after {
      content: " ";
      display: table; }
    .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs:after {
      clear: both; }
    @media screen and (min-width: 992px) {
      .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs {
        width: 90%;
        margin: 0 auto; } }
    .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs .views-row h3 {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 15px; }
      .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs .views-row h3 a {
        display: block; }
        .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .view-labs .views-row h3 a:after {
          float: right; }
  .front .panel-appel-home .footer-section .pane-labs-panel-pane-2 .pane-title {
    color: #666666;
    text-align: center;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 40px;
    margin-bottom: 10px; }

.front .panel-appel-home .footer-section .pane-bundle-text {
  height: 100%;
  text-align: center;
  padding-top: 10px;
  border-top: 5px solid #e7751d; }
  @media screen and (min-width: 768px) {
    .front .panel-appel-home .footer-section .pane-bundle-text:hover {
      background: #f7f7f7; } }
  .front .panel-appel-home .footer-section .pane-bundle-text .pane-title {
    color: #666666;
    text-align: center;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 40px;
    margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .front .panel-appel-home .footer-section .pane-bundle-text .fieldable-panels-pane {
      width: 80%;
      margin: 0 auto; } }

@media screen and (max-width: 767px) {
  .front .panel-appel-home .footer-section .footer-left {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .front .panel-appel-home .footer-section .footer-right {
    width: 100%; } }

.pane-news-panel-pane-5 .pane-title {
  color: #666666;
  text-align: center;
  font-family: "1898Sans-Regular", sans-serif;
  font-size: 40px;
  margin-bottom: 10px; }

.pane-news-panel-pane-5 .views-field-field-event-date {
  margin: 5px 0;
  font-weight: 700; }

.pane-news-panel-pane-5 .view-footer {
  clear: both;
  padding-top: 20px;
  text-align: center; }

/* =============================================================================
   ~Homepage
   ========================================================================== */
/**
 * Home Grid: Layout
 *
 */
.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  padding-top: 5px; }
  @media screen and (max-width: 767px) {
    .title-bar {
      font-size: 26px; } }

.front #main {
  max-width: 100%;
  margin: 0;
  padding: 0; }

#region1 {
  width: 100%; }
  @media screen and (min-width: 768px) {
    #region1 {
      width: 68%;
      float: left; } }
  @media screen and (min-width: 992px) {
    #region1 {
      width: 68%;
      float: left; } }

#region2 {
  width: 100%; }
  @media screen and (min-width: 768px) {
    #region2 {
      width: 32%;
      float: left; } }
  @media screen and (min-width: 992px) {
    #region2 {
      width: 32%;
      float: left; } }

#region3 {
  background: #e5e4e3;
  width: 100%;
  clear: both; }
  @media screen and (min-width: 768px) {
    #region3 {
      float: left; } }
  @media screen and (min-width: 992px) {
    #region3 {
      float: left; } }

/**
 * Home Grid: Gallery
 *
 */
.photo-gallery-feature {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 200px;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .photo-gallery-feature {
      height: 615px; } }
  @media screen and (min-width: 992px) {
    .photo-gallery-feature {
      height: 615px; } }

.photo-gallery-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (min-width: 768px) {
    .photo-gallery-image {
      height: 615px; } }
  @media screen and (min-width: 992px) {
    .photo-gallery-image {
      height: 615px; } }

.photo-gallery-image.one {
  background-image: url(../images/slide-1.jpg); }

.photo-gallery-image.two {
  background-image: url(../images/slide-2.jpg); }

.photo-gallery-image.three {
  background-image: url(../images/slide-3.jpg); }

.photo-gallery-image.four {
  background-image: url(../images/slide-4.jpg); }

.photo-gallery-image.five {
  background-image: url(../images/slide-5.jpg); }

.photo-gallery-image.six {
  background-image: url(../images/slide-6.jpg); }

.photo-gallery-image.seven {
  background-image: url(../images/slide-7.jpg); }

.gallery-container {
  position: absolute;
  top: 33%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 101; }
  @media screen and (min-width: 768px) {
    .gallery-container {
      top: 42%; } }
  @media screen and (min-width: 992px) {
    .gallery-container {
      margin: 0 auto;
      width: 80%;
      top: 42%; } }

.gallery-container h1 {
  margin: 0;
  text-transform: uppercase;
  color: white;
  padding: 0;
  text-shadow: 1px 1px 2px rgba(51, 56, 60, 0.9);
  line-height: 1.0;
  border-bottom: 0 !important;
  font-size: 40px; }
  @media screen and (min-width: 768px) {
    .gallery-container h1 {
      font-size: 54px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .gallery-container h1 {
      font-size: 64px; } }

.gallery-container p {
  color: white;
  text-shadow: 1px 1px 1px rgba(96, 100, 106, 0.9);
  margin: 0 0 20px;
  padding-bottom: .25em;
  font-size: 18px; }
  @media screen and (min-width: 768px) {
    .gallery-container p {
      font-size: 20px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .gallery-container p {
      font-size: 24px; } }

.gallery-container a {
  text-decoration: none; }

.gallery-container a:hover {
  text-decoration: none; }

.round-buttons:hover {
  color: #ffc72c;
  background-color: #e7751d;
  border: 1px solid #fff; }

/**
 * Home Grid: Tile Styles
 *
 */
.home-callout {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: block;
  text-decoration: none; }

.home-callout > p {
  margin: 0 0 15px; }
  @media screen and (min-width: 768px) {
    .home-callout > p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      /* IE 9 */
      -webkit-transform: translate(-50%, -50%);
      /* Safari and Chrome */
      -o-transform: translate(-50%, -50%);
      /* Opera */
      -moz-transform: translate(-50%, -50%);
      /* Firefox */
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .home-callout > p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      /* IE 9 */
      -webkit-transform: translate(-50%, -50%);
      /* Safari and Chrome */
      -o-transform: translate(-50%, -50%);
      /* Opera */
      -moz-transform: translate(-50%, -50%);
      /* Firefox */
      z-index: 90;
      margin: 0; } }

.ie8 .home-callout > p {
  position: static; }

.grid-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */
  display: none;
  z-index: 100;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #d8d2c9;
  /* The Fallback */
  background: rgba(216, 210, 201, 0.9); }
  .grid-overlay h2 {
    font-size: 66px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Safari and Chrome */
    -o-transform: translate(-50%, -50%);
    /* Opera */
    -moz-transform: translate(-50%, -50%);
    /* Firefox */
    margin: 0;
    text-transform: uppercase;
    color: #e7751d; }

@media screen and (min-width: 992px) {
  .hover .grid-overlay {
    display: block; } }

.home-callout .grid-overlay {
  background: #e5e4e3;
  /* The Fallback */
  background: rgba(229, 228, 227, 0.9); }

.ie8 .hover .grid-overlay {
  display: none; }

/**
 * Home Grid: News Tile
 *
 */
.news-container {
  padding: 5% 5% 2% 5%; }
  @media screen and (min-width: 768px) {
    .news-container {
      height: 32.5%; } }
  @media screen and (min-width: 992px) {
    .news-container {
      height: 32.5%; } }

.news-container p {
  font-weight: 400;
  line-height: 1.3em;
  width: 92%; }
  @media screen and (min-width: 768px) {
    .news-container p {
      font-size: 18px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .news-container p {
      font-size: 20px; } }

.news-photo-block {
  background: url(../images/photo1.jpg) no-repeat;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .news-photo-block {
      height: 35%; } }
  @media screen and (min-width: 992px) {
    .news-photo-block {
      height: 35%; } }

/**
 * Home Grid: About Tile
 *
 */
.about-alzheimer {
  background: #fff;
  clear: both;
  width: 100%;
  position: relative;
  padding: 5% 5% 2% 5%; }
  @media screen and (min-width: 768px) {
    .about-alzheimer {
      float: left;
      width: 50%;
      height: 340px; } }
  @media screen and (min-width: 992px) {
    .about-alzheimer {
      float: left;
      width: 50%;
      height: 340px; } }

.about-container p {
  width: 92%;
  font-weight: 400;
  line-height: 1.3em; }
  @media screen and (min-width: 768px) {
    .about-container p {
      font-size: 15px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .about-container p {
      font-size: 20px; } }

/**
 * Home Grid: Video Modal Tile
 *
 */
.video-section {
  background: url(../images/drpaul.jpg) no-repeat;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .video-section {
      width: 50%;
      height: 340px;
      float: left; } }
  @media screen and (min-width: 992px) {
    .video-section {
      width: 50%;
      height: 340px;
      float: left; } }

/**
 * Home Grid: Image Modal Tile (in place of Video Modal Tile)
 * Change file to set image
 *
 */
.homepage-hero_link {
  height: 200px;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .homepage-hero_link {
      width: 50%;
      height: 340px;
      float: left; } }
  @media screen and (min-width: 992px) {
    .homepage-hero_link {
      width: 50%;
      height: 340px;
      float: left; } }

.homepage-hero_image {
  background: url(/sites/default/files/news_images/li_gan_final.jpg) no-repeat;
  background-size: cover;
  background-position: 0% 50%;
  overflow: hidden;
  width: 100%; }
  .homepage-hero_image:hover {
    transform: scale(1.1); }

/**
 * Home Grid: Project Tile
 *
 */
.projects-container {
  padding: 5% 5% 2% 5%; }
  @media screen and (min-width: 768px) {
    .projects-container {
      height: 32.5%; } }
  @media screen and (min-width: 992px) {
    .projects-container {
      height: 32.5%; } }

.projects-container p {
  width: 92%;
  font-weight: 400;
  line-height: 1.3; }
  @media screen and (min-width: 768px) {
    .projects-container p {
      font-size: 18px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .projects-container p {
      font-size: 20px; } }

/**
 * Home Grid: Donation Tile
 *
 */
.donation-photo {
  background: #e5e4e3 url(../images/photo3.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 150px;
  height: inherit;
  clear: both; }
  @media screen and (min-width: 768px) {
    .donation-photo {
      height: 232px;
      width: 50%;
      float: left; } }
  @media screen and (min-width: 992px) {
    .donation-photo {
      height: 232px;
      width: 50%;
      float: left; } }

.donation-container {
  width: 100%;
  clear: none;
  background: #e5e4e3;
  position: relative;
  padding: 5% 5% 2% 5%; }
  @media screen and (min-width: 768px) {
    .donation-container {
      float: left;
      width: 50%;
      height: 232px; } }
  @media screen and (min-width: 992px) {
    .donation-container {
      float: left;
      width: 50%;
      height: 232px; } }

.donation-container p {
  width: 92%;
  font-weight: 400;
  line-height: 1.3em; }
  @media screen and (min-width: 768px) {
    .donation-container p {
      font-size: 18px;
      transition: all .4s ease 0s; } }
  @media screen and (min-width: 992px) {
    .donation-container p {
      font-size: 20px; } }

footer {
  float: left;
  width: 100%;
  padding-top: 0px; }

a.fancybox-video {
  border: 0; }
  a.fancybox-video:after {
    display: none !important; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.video .playhead {
  margin-top: 0px; }

.video:hover .playhead, .video:focus .playhead {
  background-color: rgba(255, 0, 0, 0.75); }

/*.wcm-cta__tile{
  width: 100%;
  border-right: 0px;
  border: 1px solid #ccc!important;
}*/
.pane-profiles-panel-pane-2 .view-content > h3, .pane-profiles-panel-pane-9 .view-content > h3 {
  margin-top: 20px;
  float: left; }

.pane-profiles-panel-pane-2 .profile-teaser, .pane-profiles-panel-pane-9 .profile-teaser {
  width: 100%;
  float: left;
  margin: 10px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd; }

.pane-profiles-panel-pane-2 h4.display-name, .pane-profiles-panel-pane-9 h4.display-name {
  margin-top: 10px; }
  .pane-profiles-panel-pane-2 h4.display-name a, .pane-profiles-panel-pane-9 h4.display-name a {
    color: #555555; }

.pane-profiles-panel-pane-2 .profile-list-image, .pane-profiles-panel-pane-9 .profile-list-image {
  margin-right: 20px; }
  .pane-profiles-panel-pane-2 .profile-list-image img, .pane-profiles-panel-pane-9 .profile-list-image img {
    margin-bottom: 0; }

h4 a {
  color: #555555; }

.pane-profiles-panel-pane-9 .profile-list-image {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0; }

@media screen and (max-width: 767px) {
  .pane-profiles-panel-pane-9 .profile-details {
    padding-left: 0; } }

.pane-node-field-profile-image {
  float: left;
  margin-right: 20px; }

.brand__lockup img {
  max-height: 84px; }

.pane-node-field-professional-title {
  font-weight: bold; }

.pane-custom.pane-1 .field-label {
  font-weight: bold;
  float: left;
  padding-right: 5px; }

#biblio-header {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px; }

.biblio-category-section {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px; }

nav.pagination-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px; }

.views-label-field-principal-investigator {
  float: left;
  font-weight: bold;
  padding-right: 5px; }

.pane-news-panel-pane-1 .view-teaser {
  margin: 20px 0; }

.pane-news-panel-pane-1 h3, .pane-news-panel-pane-1 h2 {
  margin-top: 0; }

.pane-news-panel-pane-1 .teaser-image img {
  margin-right: 20px; }

footer {
  margin-top: 40px; }

#region2, #region1, #region3 {
  background-color: #cf4520; }
  #region2 a, #region1 a, #region3 a {
    color: #fff; }
    #region2 a p, #region1 a p, #region3 a p {
      line-height: 1.6em; }
    #region2 a .grid-overlay h2, #region1 a .grid-overlay h2, #region3 a .grid-overlay h2 {
      text-transform: capitalize; }

.home-callout {
  background-color: #cf4520; }

.pane-node-title h1, .pane-bundle-text h1 {
  margin-bottom: 20px; }

/*News node page style*/
.node-type-news-post .post-date {
  text-transform: uppercase;
  color: #777;
  font-family: "1898Sans-Bold",sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px; }

.node-type-news-post .hero-image {
  text-align: center; }
  .node-type-news-post .hero-image img {
    width: auto; }

/* =Sharing Icons
----------------------------------------------------------*/
/*
.social-share {
  margin: 15px 0;
  padding: 0;
}

.social-share li {
  display: inline-block;
  height: 30px;
  padding: 0 5px;
  @include text-hide();
}

.social-share a {
  color: $wcm-bright-orange;
  &:before {
    font-size: 22px;
    @include fontello();
    @include v-center;
  }
  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.social-share__facebook a {
  &:before {
    content:'\e804';
  }
}

.social-share__twitter a {
  &:before {
    content:'\e807';
  }
}

.social-share__linkedin a {
  &:before {
    content:'\e806';
  }
}*/
/**
 * Navigation Styling
 */
#primary-nav .level-1 {
  width: 16.667%;
  padding: 8px 2px; }

.pane-projects-panel-pane-7 .view-projects {
  border-top: 1px solid #dddddd; }
  .pane-projects-panel-pane-7 .view-projects .views-field-field-project-investigator .views-label {
    font-weight: bold; }
  .pane-projects-panel-pane-7 .view-projects .views-field-field-project-investigator .field-content {
    display: inline-block; }

.pane-profiles-panel-pane-8 .view-profiles {
  border-top: 1px solid #dddddd;
  padding-top: 20px;
  margin-bottom: 20px; }
  .pane-profiles-panel-pane-8 .view-profiles:before, .pane-profiles-panel-pane-8 .view-profiles:after {
    content: " ";
    display: table; }
  .pane-profiles-panel-pane-8 .view-profiles:after {
    clear: both; }
  .pane-profiles-panel-pane-8 .view-profiles .view-header {
    text-transform: uppercase;
    color: #666666;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 10px; }
  .pane-profiles-panel-pane-8 .view-profiles .view-content li {
    margin: 0;
    list-style: inside; }

.pane-node-field-lab-slideshow-images {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #dddddd; }
  .pane-node-field-lab-slideshow-images a {
    border: none;
    width: 100%; }
    .pane-node-field-lab-slideshow-images a img {
      width: 100%; }

.view-featured-jobs .views-row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd; }

.pane-labs-panel-pane-1 .view-labs:before, .pane-labs-panel-pane-1 .view-labs:after {
  content: " ";
  display: table; }

.pane-labs-panel-pane-1 .view-labs:after {
  clear: both; }

.pane-labs-panel-pane-1 .view-labs .views-row {
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0; }
  .pane-labs-panel-pane-1 .view-labs .views-row:nth-child(odd) {
    clear: left; }
    @media screen and (min-width: 768px) {
      .pane-labs-panel-pane-1 .view-labs .views-row:nth-child(odd) {
        padding-right: 5px; } }
  @media screen and (min-width: 768px) {
    .pane-labs-panel-pane-1 .view-labs .views-row:nth-child(even) {
      padding-left: 5px; } }

.pane-labs-panel-pane-1 .view-labs .views-field-title {
  position: relative;
  z-index: 1; }
  .pane-labs-panel-pane-1 .view-labs .views-field-title h3 {
    margin: 0; }
    .pane-labs-panel-pane-1 .view-labs .views-field-title h3 a {
      color: #fff;
      display: block;
      padding: 15px;
      position: absolute;
      text-align: center;
      width: 100%;
      background: #cf4520;
      opacity: 0.8;
      mix-blend-mode: multiply; }
      .pane-labs-panel-pane-1 .view-labs .views-field-title h3 a:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      .pane-labs-panel-pane-1 .view-labs .views-field-title h3 a:after {
        color: #fff; }

.pane-labs-panel-pane-1 .view-labs .views-field-field-lab-thumbnail-image img {
  width: 100%; }

.pane-labs-panel-pane-1 .view-labs .views-field-nothing {
  position: relative;
  overflow: hidden; }
  .pane-labs-panel-pane-1 .view-labs .views-field-nothing:hover img {
    transform: scale(1.1); }
  .pane-labs-panel-pane-1 .view-labs .views-field-nothing a span {
    color: #fff;
    display: block;
    padding: 15px;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 0; }
    .pane-labs-panel-pane-1 .view-labs .views-field-nothing a span:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #b31b1b;
      opacity: 0.8;
      z-index: 1; }
      @supports (mix-blend-mode: multiply) {
        .pane-labs-panel-pane-1 .view-labs .views-field-nothing a span:before {
          mix-blend-mode: multiply;
          opacity: 1; } }
    .pane-labs-panel-pane-1 .view-labs .views-field-nothing a span h3 {
      position: relative;
      color: #fff;
      margin-top: 0;
      z-index: 2; }
      .pane-labs-panel-pane-1 .view-labs .views-field-nothing a span h3:after {
        content: '\e80d';
        color: #fff;
        padding-left: 5px;
        font-size: 60%;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
  .pane-labs-panel-pane-1 .view-labs .views-field-nothing img {
    width: 100%;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; }

.border-callout {
  padding: 20px 0;
  margin: 20px 0;
  border: 2px solid #ddd;
  border-width: 2px 0; }

.event-teaser {
  margin: 0 0 30px; }
  .event-teaser .teaser-title {
    margin-top: 0; }
  .event-teaser .views-field-field-event-date {
    margin: 5px 0;
    font-weight: 700; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

.node-type-laboratory .cycle-slideshow {
  border: none; }
  .node-type-laboratory .cycle-slideshow .cycle-pause {
    position: absolute;
    display: block;
    top: 15px;
    left: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px; }
  .node-type-laboratory .cycle-slideshow .cycle-pause, .node-type-laboratory .cycle-slideshow .cycle-prev, .node-type-laboratory .cycle-slideshow .cycle-next {
    font-size: 18px;
    font-family: 'Glyphicons Halflings';
    color: #fff;
    background-color: #cf4520;
    opacity: 0.7;
    z-index: 101;
    text-align: center;
    cursor: pointer; }
    .node-type-laboratory .cycle-slideshow .cycle-pause:hover, .node-type-laboratory .cycle-slideshow .cycle-prev:hover, .node-type-laboratory .cycle-slideshow .cycle-next:hover {
      opacity: .9; }
  .node-type-laboratory .cycle-slideshow .cycle-pause:before {
    content: "\e073"; }
  .node-type-laboratory .cycle-slideshow .cycle-pause.paused:before {
    content: "\e072"; }
  .node-type-laboratory .cycle-slideshow .cycle-prev, .node-type-laboratory .cycle-slideshow .cycle-next {
    display: none;
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    width: 30px;
    height: 50px;
    line-height: 50px;
    opacity: 0.7; }
    @media screen and (min-width: 768px) {
      .node-type-laboratory .cycle-slideshow .cycle-prev, .node-type-laboratory .cycle-slideshow .cycle-next {
        display: block; } }
    @media screen and (min-width: 992px) {
      .node-type-laboratory .cycle-slideshow .cycle-prev, .node-type-laboratory .cycle-slideshow .cycle-next {
        top: 50%;
        transform: translateY(-50%); } }
  .node-type-laboratory .cycle-slideshow .cycle-prev {
    left: 0; }
    .node-type-laboratory .cycle-slideshow .cycle-prev:before {
      content: "\e079"; }
  .node-type-laboratory .cycle-slideshow .cycle-next {
    right: 0; }
    .node-type-laboratory .cycle-slideshow .cycle-next:before {
      content: "\e080"; }
